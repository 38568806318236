import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Slider,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { Checkbox } from "@ui/index";

import { useFormikContext } from "formik";
import get from "lodash.get";
import isNill from "lodash/isNil";

import { useIsDesktop } from "@/hooks/layout";
import { MAX_VIEWS } from "@/pages/Licensing/utils/constants";

interface IFormikTextInputProps {
  sx?: SxProps;
  fieldType?: EFormikFieldType;
  fieldName: string;
  label: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  multiLine?: boolean;
  rows?: number;
  autoComplete?: string;
  isSliderWithTextFields?: boolean;
  maxSlider?: number;
  minSlider?: number;
  maxSliderPlaceholder?: string;
  step?: number;
  InputAdornment?: ReactNode;
  checkIsValid?: boolean;
  inputRef?: (ref: any) => void;
  isOneMillionPlusFormat?: boolean;
  isBudget?: boolean;
}

export enum EFormikFieldType {
  Text = "Text",
  CheckBox = "CheckBox",
  ToggleButton = "ToggleButton",
  Slider = "Slider",
}

const FormikInput: FC<IFormikTextInputProps> = ({
  fieldName,
  label,
  disabled = false,
  placeholder = "",
  type = "text",
  fieldType = EFormikFieldType.Text,
  multiLine = false,
  rows,
  autoComplete = "on",
  isSliderWithTextFields = false,
  maxSlider = 0,
  minSlider = 0,
  maxSliderPlaceholder = "",
  step = 1,
  InputAdornment,
  checkIsValid = false,
  inputRef,
  isOneMillionPlusFormat = false,
  isBudget = false,
  sx,
}) => {
  const { values, errors, handleChange, handleBlur, touched, setFieldValue } = useFormikContext<any>();

  const handleSetField = (field: string, value: number | number[]) => {
    setFieldValue(field, value);
  };

  const formatSliderTextField = (value: number | string, isOneMillionPlusFormat: boolean, isBudget: boolean) => {
    if (isNill(value)) {
      return value;
    }

    if (isOneMillionPlusFormat) {
      return value >= MAX_VIEWS ? "1M+" : value;
    }

    return isBudget ? `${value} USD` : value;
  };

  const isDesktop = useIsDesktop();

  const { t } = useTranslation("formikInput");

  const checkIsValidCondition = get(values, fieldName) ? InputAdornment : null;

  return (
    <FormControl
      fullWidth={fieldType !== EFormikFieldType.CheckBox}
      hiddenLabel={!values[fieldName]}
      error={!!errors[fieldName] && !!touched[fieldName]}
    >
      {fieldType === EFormikFieldType.Text && (
        <TextField
          inputRef={(ref) => inputRef && inputRef(ref)}
          disabled={disabled}
          fullWidth
          variant="outlined"
          name={fieldName}
          type={type}
          onChange={handleChange}
          onBlur={handleBlur}
          value={get(values, fieldName)}
          placeholder={placeholder}
          error={!!errors[fieldName] && !!touched[fieldName]}
          multiline={multiLine}
          rows={rows}
          autoComplete={autoComplete}
          InputProps={
            checkIsValid
              ? {
                  endAdornment: checkIsValidCondition,
                }
              : { endAdornment: InputAdornment }
          }
          sx={{
            textarea: {
              paddingLeft: "0 !important",
            },
            ".MuiInputBase-root": {
              paddingRight: InputAdornment ? "16px" : undefined,
            },
            ...sx,
          }}
        />
      )}

      {errors[fieldName] && touched[fieldName] && fieldType !== EFormikFieldType.Slider && isDesktop && (
        <FormHelperText id={fieldName}>
          <span style={{ color: "#FBF4EC" }}>Error Title: </span>

          {errors[fieldName] as string}
        </FormHelperText>
      )}

      {fieldType === EFormikFieldType.CheckBox && (
        <FormControlLabel
          control={
            <Checkbox name={fieldName} onChange={handleChange} onBlur={handleBlur} checked={values[fieldName]} />
          }
          disabled={disabled}
          label={label}
          labelPlacement="end"
          sx={sx}
        />
      )}

      {fieldType === EFormikFieldType.ToggleButton && (
        <>
          <FormLabel sx={{ textAlign: "start", marginBottom: "16px" }}>{label}</FormLabel>

          <ToggleButtonGroup
            value={get(values, fieldName)}
            onChange={(_, value) => handleSetField(fieldName, value)}
            exclusive
            id={fieldName}
            sx={{ flexWrap: "wrap" }}
          >
            <ToggleButton value="0-100">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                0 - 100
              </Box>
            </ToggleButton>
            <ToggleButton value="100-1k">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                100 - 1K
              </Box>
            </ToggleButton>
            <ToggleButton value="1k-10k">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                1K - 10K
              </Box>
            </ToggleButton>
            <ToggleButton value="10k-100k">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                10K - 100K
              </Box>
            </ToggleButton>
            <ToggleButton value="100k-500k">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                100K - 500K
              </Box>
            </ToggleButton>
            <ToggleButton value="500k-1m">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                500K - 1M
              </Box>
            </ToggleButton>
            <ToggleButton value=">1m">
              <Box bgcolor="rgba(69, 52, 37, 0.80)" padding="16px 8px" borderRadius="8px">
                {">"} 1M
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      )}

      {fieldType === EFormikFieldType.Slider && (
        <>
          <FormLabel sx={{ textAlign: "start", marginBottom: "16px" }}>{label}</FormLabel>

          <Slider
            name={fieldName}
            min={minSlider}
            max={maxSlider}
            step={step}
            value={get(values, fieldName)?.every((el: number) => el === null) ? [0, maxSlider] : get(values, fieldName)}
            onChange={(_, value) => handleSetField(fieldName, value)}
          />

          {isSliderWithTextFields && isDesktop && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" justifyContent="center" alignItems="center" spacing="16px">
                <FormLabel sx={{ textAlign: "start" }}>{t("from")}</FormLabel>

                <TextField
                  disabled={disabled}
                  variant="outlined"
                  name={fieldName}
                  type="text"
                  onChange={(event) =>
                    !isNaN(Number(event.target.value)) &&
                    handleSetField(fieldName, [Number(event.target.value), get(values, fieldName)[1]])
                  }
                  onBlur={handleBlur}
                  value={formatSliderTextField(get(values, fieldName)[0], isOneMillionPlusFormat, isBudget)}
                  placeholder={isBudget ? `${t("notApplicable")} USD` : `${t("notApplicable")}`}
                  error={!!errors[fieldName] && !!touched[fieldName]}
                  autoComplete={autoComplete}
                />
              </Stack>

              <Stack direction="row" justifyContent="center" alignItems="center" spacing="16px">
                <FormLabel sx={{ textAlign: "start" }}>{t("to")}</FormLabel>

                <TextField
                  disabled={disabled}
                  variant="outlined"
                  name={fieldName}
                  type="text"
                  onChange={(event) =>
                    !isNaN(Number(event.target.value)) &&
                    handleSetField(fieldName, [get(values, fieldName)[0], Number(event.target.value)])
                  }
                  onBlur={handleBlur}
                  value={formatSliderTextField(get(values, fieldName)[1], isOneMillionPlusFormat, isBudget)}
                  placeholder={maxSliderPlaceholder}
                  error={!!errors[fieldName] && !!touched[fieldName]}
                  multiline={multiLine}
                  rows={rows}
                  autoComplete={autoComplete}
                />
              </Stack>
            </Stack>
          )}

          {isSliderWithTextFields && !isDesktop && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
              <TextField
                disabled={disabled}
                variant="outlined"
                name={fieldName}
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={get(values, fieldName)[0]}
                placeholder={t("notApplicable") ?? ""}
                error={!!errors[fieldName] && !!touched[fieldName]}
                autoComplete={autoComplete}
                sx={{ width: "40%" }}
              />

              <Box width="20%">
                <FormLabel sx={{ textAlign: "start" }}>-</FormLabel>
              </Box>

              <TextField
                disabled={disabled}
                variant="outlined"
                name={fieldName}
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={get(values, fieldName)[1]}
                placeholder={maxSliderPlaceholder}
                error={!!errors[fieldName] && !!touched[fieldName]}
                multiline={multiLine}
                rows={rows}
                autoComplete={autoComplete}
                sx={{ width: "40%" }}
              />
            </Stack>
          )}
        </>
      )}
    </FormControl>
  );
};

export default FormikInput;
