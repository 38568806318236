import { AccordionSummary, Typography } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

export const AccordionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  text-transform: capitalize;

  ${isSmallDesktop} {
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.0125rem;
  }
`;

export const AccordionDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;

  ${isSmallDesktop} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<{ withoutMargin?: boolean }>`
  .Mui-expanded {
    ${({ withoutMargin }) => (withoutMargin ? "" : "margin-top: 12px")};
  }
`;
