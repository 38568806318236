import { Backdrop, Dialog, Stack, styled } from "@mui/material";

export const StyledBackDrop = styled(Backdrop)`
  backdrop-filter: blur(3px);
  background: rgba(42, 33, 30, 0.7);
`;

export const CustomDialog = styled(Dialog)`
  & ::-webkit-scrollbar-thumb {
    height: 51px;
    width: 4px;
    background-color: #ac8d75;
  }

  & ::-webkit-scrollbar {
    width: 4px;
  }

  & ::-webkit-scrollbar-track {
    background-color: #5c412a;
    margin: 4px 4px 8px 0;
  }

  .MuiDialog-paper {
    overflow: hidden;
    overflow-y: hidden;
  }

  @media only screen and (min-width: 1250px) {
    .MuiDialog-paper {
      width: 70%;
      max-width: 650px;
    }
  }
`;

export const ScrollWrapper = styled(Stack)`
  display: block;
  width: 100%;
  max-height: calc(100dvh - 128px - 10px);
  overflow: auto;
  position: relative;
`;
