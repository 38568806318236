import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Text } from "@ui/typography";

import { useIsDesktop } from "@hooks/layout";

import { ChoiceButtons } from "@components/QuestionFlowDialog/components/ChoiceButtons";

import * as Styled from "../styles";
import { formatButtonsData, THandleNextStepProp } from "../utils";

export const ChoiceSection: FC<THandleNextStepProp> = ({ handleLeftChoice, handleRightChoice, type }) => {
  const isDesktop = useIsDesktop();

  const { t } = useTranslation(isDesktop ? "questionFlow" : "questionFlowMobile", { keyPrefix: `basicSteps.${type}` });

  return (
    <Styled.ContentWrapper $isChoiceStep alignItems={"center"}>
      <Text isHeading size={isDesktop ? 1.5 : 1.125} isFullWidth textAlign="center" lineHeight={140}>
        <Trans i18nKey={"title"} t={t} />
      </Text>
      <ChoiceButtons buttons={formatButtonsData(type, handleLeftChoice, handleRightChoice)} />
    </Styled.ContentWrapper>
  );
};
