import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Box, Grid, Stack, Typography } from "@mui/material";

import { Button as GradientButton, IconButton, NavLink } from "@ui/index";

import { useIsBigDesktop } from "@hooks/layout";

import { contactNavItems, freeUseNavItems, licensingNavItems } from "@layouts/constants";

import { FavoritesHeartIcon, GeneralLogo, ShopIcon } from "@/assets/svg";
import { StyledDivider } from "@/components/components";
import { useAuth } from "@/hooks/auth";
import AccountMenu from "@/layouts/components/AccountMenu";
import { SecondHeaderContainer } from "@/layouts/components/components";
import { useAppSelector } from "@/store/hooks";
import { getFavoritesState } from "@/store/selectors/getFavorites";
import { ERoutes } from "@/utils/constants";

import { DesktopDropdown } from "./DesktopDropdown";

type TSecondHeader = {
  handleOpenDialog: (isLogin: boolean) => void;
};

const SecondHeader: FC<TSecondHeader> = ({ handleOpenDialog }) => {
  const { t } = useTranslation("navigation");

  const navigate = useNavigate();

  const { isLoggedIn, isLoading } = useAuth();

  const isBigDesktop = useIsBigDesktop();

  const favoritesState = useAppSelector(getFavoritesState);

  const activePopperHandler = useRef<() => void | null>(null);

  return (
    <>
      <SecondHeaderContainer>
        <Grid
          container
          justifyContent={isBigDesktop ? "space-around" : "space-between"}
          direction="row"
          alignItems="center"
          margin="0 auto"
          height="100%"
          width="100%"
          maxWidth={"xl"}
          paddingX={{ xs: "16px", md: "40px", lg: "250px" }}
          minHeight="95px"
        >
          <Grid item xs={2} lg="auto">
            <Link to={ERoutes.Home}>
              <Stack height="32px" width={{ xs: "auto", lg: "259px" }}>
                <GeneralLogo style={{ height: "100%", width: "100%" }} />
              </Stack>
            </Link>
          </Grid>

          <Grid item md={8} lg>
            <Stack direction="row" spacing="32px" alignItems="center" justifyContent="center">
              <NavLink to={ERoutes.Catalog} close={() => activePopperHandler.current && activePopperHandler.current()}>
                {t("music")}
              </NavLink>

              <DesktopDropdown
                title={t("Free Use")}
                navItems={freeUseNavItems}
                activePopperHandler={activePopperHandler}
              />

              <DesktopDropdown
                title={t("licensing")}
                navItems={licensingNavItems}
                activePopperHandler={activePopperHandler}
              />

              <DesktopDropdown
                title={t("contacts")}
                navItems={contactNavItems}
                activePopperHandler={activePopperHandler}
              />

              <NavLink
                to={ERoutes.Deals}
                end
                close={() => activePopperHandler.current && activePopperHandler.current()}
              >

                {t("deals")}
              </NavLink>

              <NavLink
                to={ERoutes.Shop}
                target="_blank"
                close={() => activePopperHandler.current && activePopperHandler.current()}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "4px",
                  }}
                >
                  {t("shop")}
                  <ShopIcon />
                </Stack>
              </NavLink>
            </Stack>
          </Grid>

          <Grid item md="auto">
            <Stack width="100%" alignItems="end" justifyContent="center">
              {isLoggedIn && !isLoading ? (
                <Stack
                  direction="row"
                  width="80%"
                  spacing="16px"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{
                    minWidth: "170px",
                    maxWidth: "259px",
                  }}
                >
                  <IconButton isTransparent onClick={() => navigate(ERoutes.Favorites)}>
                    <Stack sx={{ position: "relative" }}>
                      <FavoritesHeartIcon width="40px" height="40px" />
                    </Stack>
                    <Stack
                      sx={{
                        position: "absolute",
                        top: "-5px",
                        right: "-10px",
                      }}
                    >
                      <Box sx={{ backgroundColor: "#FFDCA8", borderRadius: "50%", width: "18px", height: "18px" }}>
                        <Typography
                          sx={{
                            fontFamily: "Kaisei Opti",
                            fontSize: "0.875rem",
                            lineHeight: "1.1rem",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "#453425",
                          }}
                        >
                          {favoritesState.length}
                        </Typography>
                      </Box>
                    </Stack>
                  </IconButton>
                  <AccountMenu />
                </Stack>
              ) : (
                <Stack direction="row" spacing="16px">
                  <GradientButton
                    width={isBigDesktop ? 125 : undefined}
                    isOutlined
                    onClick={() => handleOpenDialog(true)}
                  >
                    {t("logIn")}
                  </GradientButton>
                  <GradientButton width={isBigDesktop ? 134 : undefined} onClick={() => handleOpenDialog(false)}>
                    {t("signUp")}
                  </GradientButton>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
        <StyledDivider $isHeader />
      </SecondHeaderContainer>
    </>
  );
};

export default SecondHeader;
