import { Box, Typography } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled from "styled-components";

export const Title = styled(Typography)`
  font-family: "Kaisei Opti";
  font-size: 1.125rem;
  color: ${({ theme }) => theme.palette.textColor.header};

  ${isSmallDesktop} {
    font-size: 1.5rem;
  }
`;

export const AlbumCover = styled.img`
  height: 200px;
  width: auto;

  border-radius: 8px;
`;

export const CircledTitle = styled(Box)<{ borderColor: string }>`
  font-family: "Kaisei Opti";
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.textColor.header};
  background: rgb(0, 0, 0, 0.1);
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  width: fit-content;

  display: flex;
  align-items: center;
  padding: 16px 24px;
`;
