import { ERoutes } from "@utils/constants";

import { DropdownNavItem } from "./types";

export const freeUseNavItems: DropdownNavItem[] = [
  { route: ERoutes.CatalogLandingFreeBackground, key: "freeBgMusic" },
  { route: ERoutes.FreeUseTerms, key: "freeUse" },
];

export const licensingNavItems: DropdownNavItem[] = [
  { route: ERoutes.CatalogLandingLicensing, key: "generalLicensing" },
  { route: ERoutes.MUSIC_CREATOR_PROGRAM, key: "ytCreatorProgram" },
];

export const contactNavItems: DropdownNavItem[] = [
  { route: ERoutes.Contact, key: "contactFormular" },
  { route: ERoutes.Licensing, key: "licenseReq" },
  { route: ERoutes.Submit, key: "submitMusic" },
];

export const dropdownNavItems = [
  { items: freeUseNavItems, key: "Free Use" },
  { items: licensingNavItems, key: "licensing" },
  { items: contactNavItems, key: "contacts" },
];

export const userMenuNavItems = [
  { route: ERoutes.Deals, key: "bestDeals" },
  { route: ERoutes.DownloadInstruction, key: "downloadInstructions" },
  { route: ERoutes.MUSIC_CREATOR_PROGRAM, key: "ytCreatorProgram" },
];
