import React, { FC, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Stack } from "@mui/material";

import { Button as GradientButton, Link } from "@ui/index";
import { Text } from "@ui/typography";

import { HEADING_FONT_WEIGHT, TEXT_FONT_WEIGHT } from "@pages/FreeUseTerms/constants";

import { useAuth } from "@hooks/auth";

import BorderStack from "@components/BorderStack";
import { VerticalConnector } from "@components/components";
import CreditText from "@components/CreditText";
import { DonationButton } from "@components/DonationButton";
import { LicenseTestButton } from "@components/LicenseTestButton";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import RequireLicense from "@components/RequireLicense";
import SocialsCarousel from "@components/SocialsCarousel";
import { HandleOpenDialog } from "@layouts/SecondRootLayout";
import { ERoutes } from "@utils/constants";
import { scrollToAnchor } from "@utils/scrollToAnchor";
import { useWindowSize } from "usehooks-ts";

import { CopyIcon } from "@/assets/svg";
import { InfoLink } from "@/components/InfoStack/components";
import RestrictionItem from "@/components/RestrictionItem";
import { useIsDesktop } from "@/hooks/layout";

import { RLink } from "./components";
import { dialogLicenseBlockParts, dialogNeverAllowedKeys, LicenseBlock } from "./constants";

type TFreeUseTermsComponent = {
  isPage?: boolean;
  composer?: string;
  songName?: string;
  videoUrl?: string;
};

const FreeUseTermsComponent: FC<TFreeUseTermsComponent> = ({ composer, songName, videoUrl, isPage = true }) => {
  const { t } = useTranslation("terms");

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);
  const navigate = useNavigate();

  const { isLoggedIn } = useAuth();

  const isDesktop = useIsDesktop();
  const { width } = useWindowSize();

  const handleOpenLoginDialog = useContext(HandleOpenDialog);

  const handleDonationClick = () => {
    document.getElementById("donation_popup_button").click();
  };

  const paddingForBlocks = isDesktop || isPage ? "24px !important" : "20px !important";
  useEffect(() => {
    if (isCopied) {
      const countdownInterval = setInterval(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearInterval(countdownInterval);
    }
  }, [isCopied]);

  const creditTextFormer = () => {
    return `Background Music: ${composer} -  ${songName}\n
       © Provided by Audio Sanctum\n
       Watch: ${videoUrl}\n
       Listen: https://spotify.audiosanctum.com\n
       Free Use Terms → https://audiosanctum.com/free-use`;
  };

  const getMainTextHeadingSize = () => {
    if (isPage && isDesktop) {
      return 2.25;
    }
    if (isPage && !isDesktop) {
      return 1.75;
    }
    return 1.5;
  };

  const getCommonTextSize = () => {
    if (!isPage || !isDesktop) {
      return 0.875;
    }

    return 1;
  };

  const getCommonTextHeadingSize = () => {
    if (!isPage || !isDesktop) {
      return 1.125;
    }

    return 1.5;
  };

  const renderLicense = ({ key, route }: LicenseBlock) => (
    <RequireLicense arrowAlign="start">
      <Text size={getCommonTextSize()} isFullWidth textAlign="left" fontWeight={TEXT_FONT_WEIGHT}>
        <Trans
          t={t}
          i18nKey={key}
          components={[<InfoLink underline="hover" href={route ? route : ""} target="_blank" isInheritSize={true} />]}
        />
      </Text>
    </RequireLicense>
  );

  const renderNotAllowed = (key: string) => (
    <RestrictionItem xMarkAlign="start">
      <Text size={getCommonTextSize()} isFullWidth textAlign="left" fontWeight={TEXT_FONT_WEIGHT}>
        <Trans t={t} i18nKey={key} components={[<InfoLink underline="hover" />]} />
      </Text>
    </RestrictionItem>
  );

  return (
    <>
      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <Stack
          width={"100%"}
          spacing={isDesktop ? "40px" : "24px"}
          marginBottom={!isDesktop ? "-16px" : undefined}
          alignItems="center"
          justifyContent="center"
          zIndex={5}
        >
          <Stack
            width={!isPage || !isDesktop ? "100%" : "75%"}
            spacing={isDesktop ? "24px" : "16px"}
            alignItems={"center"}
          >
            <Stack width={width < 450 ? "75%" : "100%"}>
              <Text
                size={getMainTextHeadingSize()}
                isHeading
                isFullWidth
                textAlign="center"
                fontWeight={TEXT_FONT_WEIGHT}
              >
                {t("dialog.mainText")}
              </Text>
            </Stack>

            {isPage && !isDesktop && <SocialsCarousel sx={{ padding: "12px 0" }} />}

            <Text size={1} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
              <Trans
                t={t}
                i18nKey="dialog.description"
                components={[
                  <InfoLink
                    underline="hover"
                    onClick={() => {
                      scrollToAnchor("creditRequirement", { block: "start" });
                    }}
                    isInheritSize={true}
                  />,
                  <InfoLink
                    underline="hover"
                    onClick={() => {
                      scrollToAnchor("usesThatRequireLicense", { block: "start" });
                    }}
                    isInheritSize={true}
                  />,
                ]}
              />
            </Text>
          </Stack>

          {((isPage && isDesktop) || !isPage) && <SocialsCarousel sx={{ padding: "12px 0" }} />}
        </Stack>
      </Stack>

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"} margin={"40px 0 0 0"}>
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack width={"100%"} gap={isPage && isDesktop ? "24px" : "16px"} zIndex={5} alignItems={"center"}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.checkQuality.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "37.5%" : "100%"} gap={"16px"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="dialog.checkQuality.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        scrollToAnchor("usesThatRequireLicense");
                      }}
                      isInheritSize={true}
                    />,
                  ]}
                />
              </Text>
            </Stack>

            <Stack alignItems="center" justifyContent="center" width={width <= 450 && isPage ? "100%" : "fit-content"}>
              <LicenseTestButton
                isFullWidth={width <= 700}
                handler={() => setOpenQuestionFlow(true)}
                sx={{
                  gap: width > 420 ? "16px" : "2px",

                  padding: `16px ${width <= 450 ? "12px" : "16px"} !important`,
                }}
                textSx={{ fontSize: isDesktop ? "1rem" : "0.875rem" }}
              >
                <Trans t={t} i18nKey="dialog.checkQuality.button" components={[width <= 450 ? <br /> : <span />]} />
              </LicenseTestButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack width={"100%"} gap={isPage && isDesktop ? "24px" : "16px"} zIndex={5} alignItems={"center"}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.download.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "67.5%" : "100%"} gap={"16px"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.download.description" />
              </Text>
            </Stack>

            <Stack alignItems="center" justifyContent="center" width={"100%"}>
              <GradientButton
                isFullWidth={width <= 700}
                isDisabled={isLoggedIn}
                onClick={() => handleOpenLoginDialog(false)}
                sx={{ lineHeight: "112.5% !important", padding: `24px ${width <= 700 ? "0" : "98px"} !important` }}
              >
                {t("dialog.download.button")}
              </GradientButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack id="creditRequirement" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack
            width={"100%"}
            gap={isPage && isDesktop ? "24px" : "16px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.credit.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "45%" : "100%"} gap={"16px"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.credit.description" />
              </Text>
            </Stack>

            <Stack spacing={isDesktop ? "16px" : "8px"} width="100%" alignItems="center" justifyContent="center">
              <Stack width={"100%"}>
                <Stack width="100%" height="100%" alignItems="center" justifyContent="center" zIndex={5}>
                  <BorderStack
                    withoutBorderOnly={isPage && !isDesktop}
                    outerSx={{ width: `fit-content !important` }}
                    innerSx={{ padding: `${isDesktop ? "24px 64px" : "16px"} !important` }}
                  >
                    <Stack alignItems="center" justifyContent="center" gap={isPage && isDesktop ? "24px" : "16px"}>
                      <Text
                        size={!isPage || !isDesktop ? 0.75 : 1}
                        isFullWidth
                        textAlign="center"
                        fontWeight={TEXT_FONT_WEIGHT}
                      >
                        <CreditText
                          composer={composer}
                          songName={songName}
                          videoUrl={videoUrl}
                          sx={{ fontSize: "inherit !important" }}
                        />
                      </Text>

                      <GradientButton
                        isFullWidth={!isDesktop}
                        isOutlined
                        width={234}
                        onClick={() => {
                          setIsCopied(true);
                          navigator.clipboard.writeText(creditTextFormer());
                        }}
                        sx={{ lineHeight: "112.5% !important", fontSize: "0.875rem", fontWeigh: HEADING_FONT_WEIGHT }}
                      >
                        <Stack direction="row" spacing="8px" alignItems="center" justifyContent="center">
                          <CopyIcon />
                          <Box>{t(isCopied ? "dialog.credit.buttonClicked" : "dialog.credit.button")}</Box>
                        </Stack>
                      </GradientButton>
                    </Stack>
                  </BorderStack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%">
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack
            width="100%"
            gap={isPage && isDesktop ? "24px" : "16px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              <Trans t={t} i18nKey="dialog.content.mainText" />
            </Text>
            <Stack width={isPage && isDesktop ? "85%" : "100%"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="dialog.content.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      href={"/contact-licensing/contact"}
                      target="_blank"
                      isInheritSize={true}
                    />,
                  ]}
                />
              </Text>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%">
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack width={"100%"} gap={isPage && isDesktop ? "24px" : "16px"} zIndex={5} alignItems={"center"}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.donation.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "72.5%" : "100%"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="dialog.donation.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        setOpenQuestionFlow(true);
                      }}
                      isInheritSize={true}
                    />,
                    <InfoLink
                      underline="hover"
                      href={ERoutes.CatalogLandingLicensing}
                      target="_blank"
                      isInheritSize={true}
                    />,
                    <InfoLink underline="hover" href={ERoutes.FreeUseTerms} target="_blank" isInheritSize={true} />,
                  ]}
                />
              </Text>
            </Stack>

            <Stack alignItems="center" justifyContent="center" width={"100%"}>
              <DonationButton
                isFullWidth={width <= 700}
                handler={() => handleDonationClick()}
                sx={{ lineHeight: "112.5% !important", padding: width >= 500 ? "8px 76px !important" : "" }}
              >
                {t("dialog.donation.button")}
              </DonationButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>
      {/* <>------------------------------------------------------------</> */}

      <Stack id="usesThatRequireLicense" width="100%" sx={{ margin: "40px 0 0 0" }}>
        <BorderStack
          withoutBorder
          outerSx={{
            background: "linear-gradient(0deg, rgba(59, 45, 33, 0.375) 0%, rgba(58, 44, 36, 0.75) 100%)",
            borderRadius: "16px",
          }}
          innerSx={{ padding: paddingForBlocks }}
        >
          <Stack width="100%" spacing={"16px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={HEADING_FONT_WEIGHT}
            >
              {t("licenseRequire.mainText")}
            </Text>

            <Stack width={isPage && isDesktop ? "90%" : "100%"} gap={isPage && isDesktop ? "24px" : "16px"}>
              {dialogLicenseBlockParts.map(renderLicense)}
            </Stack>

            <Stack
              flexDirection={width <= 700 ? "column" : "row"}
              width="100%"
              alignItems="center"
              justifyContent="center"
              gap="16px"
            >
              <RLink to={ERoutes.Licensing} target="_blank" isFullWidth={width <= 700}>
                <GradientButton
                  isFullWidth={width <= 700}
                  sx={{
                    padding: `16px ${isDesktop ? 48 : 24}px !important`,
                    fontSize: "0.875rem",
                    fontWeigh: HEADING_FONT_WEIGHT,
                    // width: isDesktop ? "fit-content !important" : "100% !important",
                    height: "fit-content !important",
                  }}
                >
                  {t("dialog.licenseRequire.button")}
                </GradientButton>
              </RLink>

              <GradientButton
                isFullWidth={width <= 700}
                onClick={() => navigate(ERoutes.MUSIC_CREATOR_PROGRAM)}
                sx={{
                  padding: `16px !important`,
                  fontSize: "0.875rem",
                  fontWeigh: HEADING_FONT_WEIGHT,
                  height: "fit-content !important",
                }}
              >
                {t("dialog.licenseRequire.secondButton")}
              </GradientButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <Stack width="100%" sx={{ margin: "40px 0 0 0" }}>
        <BorderStack
          withoutBorder
          outerSx={{
            background: "linear-gradient(0deg, rgba(59, 45, 33, 0.375) 0%, rgba(58, 44, 36, 0.75) 100%)",
            borderRadius: "16px",
          }}
          innerSx={{ padding: paddingForBlocks }}
        >
          <Stack width="100%" spacing={"24px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={HEADING_FONT_WEIGHT}
            >
              {t("dialog.neverAllowed.mainText")}
            </Text>

            <Stack width={isPage && isDesktop ? "90%" : "100%"} gap={isPage && isDesktop ? "24px" : "8px"}>
              {dialogNeverAllowedKeys.map(renderNotAllowed)}
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <Stack textAlign="center" sx={{ margin: "40px 0 0 0", padding: "0 0 16px 0" }} zIndex={5}>
        <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
          <Trans
            t={t}
            i18nKey="dialog.questions"
            components={[
              <Link
                component="button"
                size="inherit"
                onClick={() => {
                  scrollToAnchor("musicFAQ");
                }}
              />,
              <Link size="inherit" href={ERoutes.Contact} target="_blank" />,
            ]}
          />
        </Text>
      </Stack>
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
    </>
  );
};

export default FreeUseTermsComponent;
