import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Logout } from "@mui/icons-material";
import { AppBar, Box, Divider, Drawer, Grid, Stack, Toolbar, Typography } from "@mui/material";

import { Button as GradientButton, IconButton } from "@ui/index";

import StyledAccordion from "@components/Accordion";
import { DonationButton } from "@components/DonationButton";
import NewDownloadModal from "@components/DownloadModal";
import { LicenseTestButton } from "@components/LicenseTestButton";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { dropdownNavItems, userMenuNavItems } from "@layouts/constants";
import { DropdownNavItem } from "@layouts/types";
import { ERoutes } from "@utils/constants";

import MainMenuBg from "@/assets/png/MainMenu/MainMenuBG.png";
import { BurgerMenu, CloseIcon, SettingsIcon, ShopIcon } from "@/assets/svg";
import { StyledDivider } from "@/components/components";
import { useAuth, useLogOut } from "@/hooks/auth";
import { NavLinkWithoutTo, SecondaryBurgerMenuNavLink } from "@/layouts/components/components";
import { ProfileAvatar } from "@/pages/Profile/components";
import { useAppSelector } from "@/store/hooks";
import { getFavoritesState } from "@/store/selectors/getFavorites";

import { FavoritesHeartIcon, GeneralLogo, MobileMenuLogo } from "../../assets/svg";

interface IBurgerMenuProps {
  burgerMenu: boolean;
  openBurgerMenu: () => void;
  closeBurgerMenu: () => void;
  handleOpenDialog: (isPage: boolean) => void;
}

const SecondaryBurgerMenu: FC<IBurgerMenuProps> = ({
  openBurgerMenu,
  closeBurgerMenu,
  burgerMenu,
  handleOpenDialog,
}) => {
  const { t } = useTranslation("navigation");
  const { isLoggedIn, isLoading, myData } = useAuth();

  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const [isAccountMenu, setIsAccountMenu] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const [donationPopup, setDonationPopup] = useState({ isOpened: false, activeStep: 1 });

  const { logoutUser, resetUser } = useLogOut();

  const navigate = useNavigate();

  const handleOpenAccountMenu = () => {
    setIsAccountMenu(true);
    openBurgerMenu();
  };

  const handleOpenBurgerMenu = () => {
    setIsAccountMenu(false);
    openBurgerMenu();
  };

  const handleNavigate = (route: ERoutes) => {
    closeBurgerMenu();
    setIsAccountMenu(false);
    setExpanded(false);
    setTimeout(() => {
      navigate(route);
    }, 500);
  };

  const handleClose = () => {
    closeBurgerMenu();
    setIsAccountMenu(false);
    setTimeout(() => {
      logoutUser();
      resetUser();
    }, 500);
  };

  const handleDonationClick = () => {
    document.getElementById("donation_popup_button").click();
  };

  const handleChange = (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const favoritesState = useAppSelector(getFavoritesState);

  const renderDescription = ({ route, key }: DropdownNavItem) => (
    <NavLinkWithoutTo onClick={() => handleNavigate(route)}>{t(key)}</NavLinkWithoutTo>
  );

  const renderNavItems = ({ items, key }: { items: DropdownNavItem[]; key: string }, i: number) => (
    <StyledAccordion
      key={i}
      title={<SecondaryBurgerMenuNavLink sx={{ margin: 0 }}>{t(key)}</SecondaryBurgerMenuNavLink>}
      description={items.map(renderDescription)}
      panel={`panel_${i}`}
      expanded={expanded}
      handleChange={handleChange}
      accordionSx={{
        border: "1px solid #635041 !important",
        borderRadius: "4px !important",
        background: "transparent !important",
      }}
      withoutMargin={true}
      sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
    />
  );

  const renderUserMenuNavItems = ({ key, route }: DropdownNavItem) => (
    <Typography
      sx={{
        width: "fit-content",
        fontFamily: "Raleway",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        textAlign: "center",
        color: "textColor.common",
      }}
      onClick={() => handleNavigate(route)}
    >
      {t(key).toUpperCase()}
    </Typography>
  );

  return (
    <AppBar
      position="relative"
      sx={{
        background: `#59412E`,
        backgroundPosition: "center",
        boxShadow: 0,
      }}
    >
      <Toolbar sx={{ px: "1rem", py: "1rem" }}>
        <Stack sx={{ maxHeight: "24px" }}>
          <Link to={isLoggedIn ? ERoutes.Catalog : ERoutes.Home}>
            <GeneralLogo style={{ height: "24px", maxWidth: "176px" }} />
          </Link>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: "100%" }}>
          <Grid item md="auto">
            <Stack direction="row" width="100%" alignItems="center" justifyContent="flex-end">
              {isLoggedIn && !isLoading ? (
                <Stack
                  sx={{
                    marginRight: "12px",
                  }}
                >
                  <IconButton
                    isTransparent
                    onClick={handleOpenAccountMenu}
                    size="small"
                    sx={{ padding: 0, justifyContent: "space-around" }}
                  >
                    <ProfileAvatar
                      sx={{
                        cursor: "pointer !important",
                        width: `32px !important`,
                        height: `32px !important`,
                        fontSize: `1.25rem !important`,
                        "&:hover": {
                          borderColor: "#ffdca8",
                          backgroundColor: "#5C412A",
                          transition: "border 0.3s ease-in-out, background-color 0.3s ease-in-out",
                        },
                      }}
                      alt={myData?.firstName.toUpperCase()}
                      src="#"
                    />
                  </IconButton>
                </Stack>
              ) : (
                <GradientButton sx={{ marginRight: "16px" }} isIconButtonLike onClick={() => handleOpenDialog(true)}>
                  {t("signIn")}
                </GradientButton>
              )}
            </Stack>
          </Grid>
          <IconButton
            isTransparent
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpenBurgerMenu}
            sx={{ p: 0 }}
          >
            <BurgerMenu />
          </IconButton>
        </Stack>

        <Drawer
          anchor="right"
          variant="temporary"
          open={burgerMenu}
          onClose={closeBurgerMenu}
          PaperProps={{
            sx: {
              backgroundImage: `url(${MainMenuBg})`,
              backgroundColor: "#302317",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              padding: "1rem",
              overflow: "hidden",
            },
          }}
        >
          {isAccountMenu ? (
            <>
              <Stack gap={"32px"}>
                <Stack direction="row" justifyContent="flex-end">
                  <IconButton isTransparent onClick={closeBurgerMenu}>
                    <CloseIcon width="18px" />
                  </IconButton>
                </Stack>
                <Stack direction="column" justifyContent="center" alignItems={"center"} gap={"8px"}>
                  <ProfileAvatar
                    sx={{
                      cursor: "pointer !important",
                      width: `48px !important`,
                      height: `48px !important`,
                      fontSize: `26px !important`,
                    }}
                    alt={myData?.firstName.toUpperCase()}
                    src="#"
                  />
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontFamily: "Kaisei Opti",
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "textColor.subtitle",
                    }}
                  >
                    {myData?.firstName}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "14px",
                      textAlign: "center",
                      color: "textColor.subtitle",
                    }}
                  >
                    {myData?.email}
                  </Typography>
                </Stack>
                <Stack gap="16px" height={"100%"} justifyContent="space-around" alignItems={"flex-start"}>
                  <Stack gap={"32px"} width={"100%"} alignItems={"flex-start"} margin={" 0 0 16px 0"}>
                    <Box sx={{ width: "100%", display: "flex", gap: "8px" }}>
                      <DonationButton handler={() => handleDonationClick()} isFullWidth={true}>
                        {t("mobile.buttons.donation")}
                      </DonationButton>
                      <LicenseTestButton handler={() => setOpenQuestionFlow(true)} isFullWidth={true}>
                        {t("mobile.buttons.test")}
                      </LicenseTestButton>
                    </Box>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems={"center"}
                      gap={"8px"}
                      onClick={() => {
                        handleNavigate(ERoutes.Favorites);
                      }}
                    >
                      <Stack
                        sx={{
                          position: "relative",
                          "& svg path": {
                            stroke: "#E0D2C1",
                          },
                        }}
                      >
                        <FavoritesHeartIcon />
                      </Stack>

                      <Stack direction={"row"} gap={"8px"}>
                        <Typography
                          sx={{
                            fontFamily: "Raleway",
                            fontSize: "24px",
                            fontWeight: "400",
                            lineHeight: "34px",
                            textAlign: "left",
                            color: "textColor.common",
                          }}
                        >
                          {t("favorites").toUpperCase()}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Kantumruy",
                            fontSize: "24px",
                            fontWeight: "300",
                            lineHeight: "34px",
                            textAlign: "left",
                            color: "textColor.common",
                          }}
                        >
                          {"( " + favoritesState.length + " )"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Divider
                    sx={{
                      width: "100%",
                      borderColor: "#635041",
                    }}
                  />

                  <Stack gap={"32px"}>{userMenuNavItems.map(renderUserMenuNavItems)}</Stack>

                  <Divider
                    sx={{
                      width: "100%",
                      borderColor: "#635041",
                    }}
                  />

                  <Stack direction="column" justifyContent="center" alignItems={"flex-start"} gap={"32px"}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                      onClick={() => handleNavigate(ERoutes.Profile)}
                    >
                      <Stack
                        sx={{
                          "& svg": {
                            width: "32px",
                            height: "32px",
                          },
                        }}
                      >
                        <SettingsIcon />
                      </Stack>
                      <Typography
                        sx={{
                          fontFamily: "Raleway",
                          fontSize: "24px",
                          fontWeight: "400",
                          lineHeight: "34px",
                          textAlign: "left",
                          color: "textColor.common",
                        }}
                      >
                        {t("mobileSettings").toUpperCase()}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap="8px" onClick={handleClose}>
                      <Logout
                        sx={{
                          width: "32px",
                          height: "32px",
                          color: "textColor.common",
                        }}
                        fontSize="small"
                      />
                      <Typography
                        sx={{
                          fontFamily: "Raleway",
                          fontSize: "24px",
                          fontWeight: "400",
                          lineHeight: "34px",
                          textAlign: "left",
                          color: "textColor.common",
                        }}
                      >
                        {t("logOut").toUpperCase()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" justifyContent="flex-end">
                <IconButton isTransparent onClick={closeBurgerMenu}>
                  <CloseIcon width="18px" />
                </IconButton>
              </Stack>
              <Stack direction="row" justifyContent="center">
                <MobileMenuLogo
                  onClick={() => {
                    closeBurgerMenu();
                    handleNavigate(ERoutes.Home);
                  }}
                  style={{ height: "40px", width: "auto", margin: "32px 0 60px 0" }}
                />
              </Stack>

              <Stack
                alignItems="center"
                height={"100%"}
                sx={{
                  paddingTop: "0",
                  paddingBottom: "80px",
                }}
              >
                <Stack gap="16px" height={"100%"} justifyContent="space-around">
                  <SecondaryBurgerMenuNavLink
                    onClick={() => {
                      handleNavigate(ERoutes.Catalog);
                    }}
                    withBorder={true}
                  >
                    {t("music")}
                  </SecondaryBurgerMenuNavLink>

                  {dropdownNavItems.map(renderNavItems)}
                  <SecondaryBurgerMenuNavLink
                    onClick={() => {
                      handleNavigate(ERoutes.Deals);
                    }}
                    withBorder={true}
                  >
                    {t("deals")}
                  </SecondaryBurgerMenuNavLink>

                  <SecondaryBurgerMenuNavLink
                    onClick={() => {
                      window.open(ERoutes.Shop);
                    }}
                    withBorder={true}
                  >
                    <Stack sx={{ alignItems: "center", flexDirection: "row", gap: "4px" }}>
                      {t("shop")}
                      <ShopIcon />
                    </Stack>
                  </SecondaryBurgerMenuNavLink>
                </Stack>
              </Stack>
            </>
          )}
        </Drawer>
      </Toolbar>
      <StyledDivider $isHeader />
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
      <NewDownloadModal
        open={donationPopup.isOpened}
        onClose={() => setDonationPopup((prev) => ({ ...prev, isOpened: false }))}
        copyrightModal={true}
        activeStep={donationPopup.activeStep}
        handleNext={() => setDonationPopup((prev) => ({ ...prev, activeStep: prev.activeStep + 1 }))}
        composer=""
      />
    </AppBar>
  );
};

export default SecondaryBurgerMenu;
