export enum Steps {
  START,
  MUSIC_USE,
  BROADCAST_NETWORK,
  MUSIC_CONTENT,
  LIVESTREAMS,
  ADVERTISEMENT,
  GIVE_CREDIT,
  FINAL_GIVE_CREDIT,
  FINAL_NEED_LICENSE,
  FINAL_NEED_BECOME_CREATOR,
  FINAL_LIVESTREAMS,
}
