export const scrollToAnchor = (id: string, options?: ScrollIntoViewOptions) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
    ...options,
  });
};

export const scrollToAnchorByRef = (element: HTMLElement, x?: number, y?: number) => {
  if (x || y) {
    window.scrollTo({
      left: x,
      top: y,
      behavior: "smooth",
    });
    return;
  }

  element.scrollIntoView({
    behavior: "smooth",
  });
};
