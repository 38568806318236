import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { Button as GradientButton, SwitchButton } from "@ui/index";

import { updateMy } from "@store/actions/user";
import { useAppDispatchUnwrap } from "@store/hooks";

import { UserMetadataForm } from "@components/UserMetadataForm";
import { EMetaDataType, METADATA_OTHER, USE_CASES } from "@utils/constants";

const Form = () => {
  const { t } = useTranslation("signup", {
    keyPrefix: "useCases",
  });

  const [selectedUseCases, setSelectedUseCases] = useState<string[]>([]);
  const [otherField, setOtherField] = useState<string>("");
  const [isAccepted, setIsAccepted] = useState<boolean>(true);

  const dispatch = useAppDispatchUnwrap();

  const handleSave = async () => {
    const otherIndex = selectedUseCases.findIndex((element) => element === METADATA_OTHER);
    if (otherIndex !== -1) {
      selectedUseCases[otherIndex] = otherField;
    }
    await dispatch(
      updateMy({
        useCases: isAccepted ? selectedUseCases : [],
      }),
    );
  };

  const isButtonValid = () => {
    if (!isAccepted) {
      return false;
    }
    const isOtherInArray = selectedUseCases.includes(METADATA_OTHER);
    if (isOtherInArray) {
      return !otherField;
    }

    return !selectedUseCases.length;
  };

  return (
    <Stack spacing="24px" alignItems="center" justifyContent="center">
      <Stack>
        <SwitchButton checked={!isAccepted} onChange={(_, checked) => setIsAccepted(!checked)} t={t} />
      </Stack>
      <UserMetadataForm
        t={t}
        selected={selectedUseCases}
        setSelected={setSelectedUseCases}
        data={USE_CASES}
        otherField={otherField}
        setOtherField={setOtherField}
        metaDataType={EMetaDataType.UseCases}
        isAccepted={isAccepted}
      >
        <GradientButton onClick={handleSave} isDisabled={isButtonValid()} isFullWidth>
          {t("buttonText")}
        </GradientButton>
      </UserMetadataForm>
    </Stack>
  );
};

export default Form;
