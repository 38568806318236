import { Button } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled, { css } from "styled-components";

type TFilterButton = {
  $isSelected: boolean;
};

export const FilterButton = styled(Button)<TFilterButton>`
  && {
    font-family: Raleway;
    font-size: 1rem;
    line-height: 130%;

    height: 46px;

    padding-inline: 24px;

    background-color: rgb(59 45 33 / 30%);
    color: #fbf4ec;
    border: 1px solid #ffdca8;
    border-radius: 8px;

    &:hover {
      color: #4b2628;
      background-color: #ffdca8;

      transition: all 0.3s ease-in;
    }

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        color: #4b2628;
        background-color: #ffdca8;
      `}
    ${isSmallDesktop} {
      height: 40px;
    }
  }
`;
