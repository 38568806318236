import { Button as LibButton } from "@mui/material";

import styled, { css } from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

type TButton = {
  $isOutlined: boolean;
  $isFullWidth: boolean;
  $isBigButton: boolean;
  $isIconButtonLike: boolean;
  width: number;
};

export const Button = styled(LibButton)<TButton>`
  && {
    font-weight: 500;
    font-size: ${({ $isIconButtonLike }) => ($isIconButtonLike ? "0.813rem" : "0.875rem")};
    font-family: Kaisei Opti;
    line-height: 135%;
    border-radius: 8px;
    box-sizing: border-box;
    width: fit-content;
    padding-inline: 16px;
    ${({ $isIconButtonLike }) =>
      $isIconButtonLike &&
      css`
        padding-right: 13px;
      `}
    background: ${({ theme, $isOutlined }) => $isOutlined && theme.palette.gradientButton.mobileBackground};
    height: ${({ $isBigButton }) => ($isBigButton ? "48px" : "40px")};

    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `}
    ${({ $isFullWidth }) =>
      $isFullWidth &&
      css`
        width: 100%;
      `}
        ${({ $isOutlined, theme, $isIconButtonLike }) =>
      $isOutlined
        ? css`
            color: ${theme.palette.textColor.highlighted};
            display: inline-block;
            position: relative;
            z-index: 0;
            border-radius: ${$isIconButtonLike ? "2px" : "8px"};

            background-color: ${({ theme }) => theme.palette.backgroundColors.iconButton};

            path {
              fill: #ffdca8;

              transition: fill 0.3s ease-in-out;
            }
            &:active {
              background-color: #ffdca8;
              color: #4b3628;

              &::after {
                opacity: 1;
              }

              path {
                fill: #4b3628;
              }
            }

            ${isSmallDesktop} {
              &:hover {
                &::after {
                  opacity: 1;
                }
              }

              &:hover {
                background-color: #ffdca8;
                color: #4b3628;

                span,
                p {
                  color: #4b3628;
                }

                path {
                  fill: #4b3628;
                }
              }
            }

            &::after {
              content: '""';
              background: #ffdca8;
              position: absolute;
              z-index: 0;
              inset: 0;
              padding: 2px;
              border-radius: ${$isIconButtonLike ? "2px" : "8px"};
              mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
              transition: opacity 300ms ease-in-out;
              opacity: 0;
            }

            &::before {
              content: "";
              position: absolute;
              z-index: -1;
              inset: 0;
              padding: ${$isIconButtonLike ? "1px" : "2px"};
              border-radius: ${$isIconButtonLike ? "2px" : "7px"};
              background: ${({ theme }) => theme.palette.gradientButton.mainGradient};
              mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
            }
          `
        : css`
            background: ${theme.palette.gradientButton.mainGradient};
            color: #191a1a;
            transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;

            padding: 8px;

            &:active {
              box-shadow: 0 4px 30px 0 rgba(254, 209, 154, 0.4);
            }
            ${isSmallDesktop} {
              &:hover {
                box-shadow: 0 4px 30px 0 rgba(254, 209, 154, 0.4);
              }
            }
          `}
        &.Mui-disabled {
      background: rgba(99, 68, 45, 0.4);
      color: ${({ theme }) => theme.palette.textColor.subtitle};

      &::before {
        content: none;
      }

      .additional-text {
        color: ${({ theme }) => theme.palette.textColor.subtitle};
      }
    }

    .additional-text {
      color: rgba(75, 55, 39, 0.8);
      font-weight: 400;
      margin-left: 0.1rem;
    }

    ${({ $isIconButtonLike }) =>
      $isIconButtonLike &&
      css`
        padding-block: 0;
        height: 32px;
      `}
    ${isSmallDesktop} {
      background: ${({ $isOutlined, theme }) => $isOutlined && theme.palette.gradientButton.desktopBackground};
      ${({ $isBigButton }) =>
        $isBigButton &&
        css`
          padding-inline: 32px;
        `}
    }
  }
`;
