import { PaletteOptions } from "@mui/material/styles/createPalette";

const palette: PaletteOptions = {
  primary: {
    main: "#997b6b",
    dark: "#907b72",
    light: "#fbf9f3",
    contrastText: "#fff",
  },
  secondary: {
    main: "#c4b4af",
    light: "#ffe3b0",
    dark: "#3a2d25",
    contrastText: "#fff",
  },
  header: {
    textColor: "rgba(251, 244, 236, 0.70)",
    activeColor: "#FFDCA8",
  },
  gradientButton: {
    mainGradient:
      "linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",
    mainGradientHover: "",
    mobileBackground: "rgba(75, 54, 40, 0.60)",
    desktopBackground: "rgba(61, 46, 36, 0.40)",
  },
  textColor: {
    common: "#E0D2C1",
    highlighted: "#FFDCA8",
    secondary: "#BA9C7E",
    header: "#FBF4EC",
    subtitle: "#AC8D75",
  },
  backgroundColors: {
    iconButton: "rgba(99, 68, 45, 0.40)",
  },
  custom: {
    red: { main: "#ffd1d1", dark: "#f73657", light: "#ff0000" },
    orange: "#FFA944",
    green: { main: "#d1ffd1", dark: "#1db954" },
    violet: "#9815FF",
    gold: "#d5b27f",
    white: "#ede4d3",
    buttonGold: "#9e8b80",
    composerText: "#ba9b82",
    placeHolderText: "#C8AA86",
  },
  background: {
    default: "#715137",
    paper: "#fafafa",
    darker: "#f0ebdd",
    light: "#f5f2f2",
  },
  text: {
    primary: "#3b3b39",
    secondary: "#aaaaab",
  },
  error: {
    main: "#FF5935",
    light: "#c7af93",
  },
  border: {
    main: "#2079FF",
    light: "#E2E8ED",
  },
  borderColors: {
    iconButton: "#BA905C",
  },
};

export default palette;
