import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";
import { Text } from "@ui/typography";

import { LicenseTestShield } from "@assets/svg";

import { useIsDesktop } from "@hooks/layout";

import { TAlbum, TTrack } from "@store/actions/track";

import { QuestionFlowDialog } from "@components/QuestionFlowDialog";

import GradientDialog from "../Dialog";
import Stepper from "../SignUpDialog/components/Stepper";
import CopyPage from "./components/CopyPage";
import DownloadPage, { EDownloadModalType } from "./components/DownloadPage";

interface INewDownloadModal {
  open: boolean;
  onClose: () => void;
  activeStep: number;
  handleNext: () => void;
  item?: TAlbum;
  song?: TTrack;
  composer: string;
  videoUrl?: string;
  copyrightModal?: boolean;
  cover?: string;
}

interface IRenderStepContent {
  step: number;
  onClose: () => void;
  handleNext: () => void;
  album?: TAlbum;
  song?: TTrack;
  composer?: string;
  videoUrl?: string;
  copyrightModal?: boolean;
  cover?: string;
  openQuestionFlowDialog: () => void;
}

export enum EDownloadModal {
  FirstStep = 1,
  SecondStep,
}

const RenderStepContent: FC<IRenderStepContent> = ({
  album,
  step,
  videoUrl = "",
  song,
  composer = "",
  handleNext,
  copyrightModal = false,
  cover,
  onClose,
  openQuestionFlowDialog,
}) => {
  if (copyrightModal) {
    return album ? (
      <CopyPage
        item={album}
        composer={composer}
        videoUrl={videoUrl}
        copyrightModal={copyrightModal}
        openQuestionFlowDialog={openQuestionFlowDialog}
      />
    ) : (
      <CopyPage
        composer={composer}
        videoUrl={videoUrl}
        copyrightModal={copyrightModal}
        openQuestionFlowDialog={openQuestionFlowDialog}
      />
    );
  }

  switch (step) {
    case EDownloadModal.FirstStep:
      return album ? (
        <DownloadPage handleNext={handleNext} item={album} type={EDownloadModalType.Album} handleClose={onClose} />
      ) : (
        <DownloadPage
          handleNext={handleNext}
          song={song}
          type={EDownloadModalType.Song}
          name={composer}
          cover={cover}
          handleClose={onClose}
        />
      );
    case EDownloadModal.SecondStep:
      return album ? (
        <CopyPage
          item={album}
          composer={composer}
          videoUrl={videoUrl}
          openQuestionFlowDialog={openQuestionFlowDialog}
        />
      ) : (
        <CopyPage composer={composer} videoUrl={videoUrl} openQuestionFlowDialog={openQuestionFlowDialog} />
      );
    default:
      return null;
  }
};

const NewDownloadModal: FC<INewDownloadModal> = ({
  open,
  onClose,
  item,
  song,
  handleNext,
  activeStep,
  composer,
  videoUrl,
  copyrightModal = false,
  cover,
}) => {
  const { t } = useTranslation("downloadModal");
  const isDesktop = useIsDesktop();
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  return (
    <>
      <GradientDialog open={open} handleClose={onClose}>
        <Stack spacing="24px" alignItems="center">
          {!copyrightModal && (
            <Stack alignSelf="center" justifyContent="center" sx={{ width: "33%" }}>
              <Stepper activeStep={activeStep === 1 ? 0 : 1} steps={["01", "02"]} />
            </Stack>
          )}

          <Stack width="100%">
            <RenderStepContent
              step={activeStep}
              onClose={onClose}
              handleNext={handleNext}
              album={item}
              composer={composer}
              song={song}
              videoUrl={videoUrl}
              copyrightModal={copyrightModal}
              cover={cover}
              openQuestionFlowDialog={() => setOpenQuestionFlow(true)}
            />
          </Stack>

          {isDesktop && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "16px",
                alignItems: "center",
                padding: "16px",
                border: "1px solid #5F5146",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px" }}>
                <LicenseTestShield />
                <Text size={0.75} maxWidth="100% !important">
                  {t("testTitle")}
                </Text>
              </Box>
              <GradientButton
                isOutlined
                onClick={() => setOpenQuestionFlow(true)}
                sx={{
                  paddingInline: "0 !important",
                  padding: "10px 16px !important",
                  height: "fit-content !important",
                  "& p": {
                    transition: "all 0.3s ease-in-out",
                  },
                  "&:hover p": {
                    color: "inherit !important",
                  },
                }}
              >
                <Text size={0.625} sx={{ maxWidth: "100% !important", fontFamily: "Kaisei Opti" }}>
                  {t("testButton")}
                </Text>
              </GradientButton>
            </Box>
          )}
        </Stack>
      </GradientDialog>
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
    </>
  );
};

export default NewDownloadModal;
