import { TextField as TextFieldLib } from "@mui/material";

import styled from "styled-components";

export const TextField = styled(TextFieldLib)`
  & .MuiOutlinedInput-input {
    padding-inline: 0;

    &::placeholder {
      font-family: Raleway;
    }
    &:focus-visible {
      outline: none;
    }
  }

  .MuiOutlinedInput-root {
    max-height: 40px;
    border: 1px solid transparent;

    &:focus {
      border: 1px solid #ffdca8;
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
    &.Mui-focused {
      border: 1px solid #ffdca8;
      outline: none;
    }
  }
`;
