import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Text } from "@ui/typography";

import { useIsDesktop } from "@hooks/layout";

import { Button } from "@/ui-kit";

import * as Styled from "../styles";
import { THandleNextStepProp } from "../utils";

export const FindOut: FC<Pick<THandleNextStepProp, "handleLeftChoice">> = ({ handleLeftChoice }) => {
  const isDesktop = useIsDesktop();

  const { t } = useTranslation(isDesktop ? "questionFlow" : "questionFlowMobile");

  return (
    <Styled.ContentWrapper alignItems={"center"}>
      <Text isHeading size={isDesktop ? 1.5 : 1.125} isFullWidth textAlign="center" mb="17px" lineHeight={140}>
        <Trans i18nKey={"basicSteps.start.title"} t={t} />
      </Text>
      <Text
        textAlign="center"
        maxWidth={isDesktop ? "100% !important" : "92% !important"}
        mb={isDesktop ? "32px" : "16px"}
        size={isDesktop ? 1 : 0.875}
      >
        {t("basicSteps.start.description")}
      </Text>
      <Button isBigButton isFullWidth onClick={() => handleLeftChoice()}>
        {t("basicSteps.start.buttonText")}
      </Button>
    </Styled.ContentWrapper>
  );
};
