import { Box, Typography } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled from "styled-components";

export const SecondHeaderContainer = styled.header`
  width: 100%;
  background-color: #59412e;
  position: relative;
`;

export const SecondaryBurgerMenuNavLink = styled(Typography)<{ withBorder?: boolean }>`
  text-decoration: none;
  font-size: 24px;
  letter-spacing: 0.01rem;
  text-transform: uppercase !important;
  line-height: 1.4rem;
  font-family: Raleway;
  cursor: pointer;

  font-weight: 400;

  color: ${({ theme }) => theme.palette.header.textColor};

  svg {
    path {
      fill: ${({ theme }) => theme.palette.header.textColor};
    }
  }

  transition: color 250ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.textColor.highlighted};
    path {
      fill: ${({ theme }) => theme.palette.textColor.highlighted};
    }
  }

  ${({ withBorder }) =>
    withBorder && "border: 1px solid #635041 !important; border-radius: 4px !important; padding: 12px 10px"}
`;

export const CopyrightTypography = styled(Typography)`
  color: rgb(199 193 189 / 70%);
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.225rem;
  letter-spacing: 0.00875rem;
  text-transform: capitalize;
  text-align: center;

  a {
    color: rgb(199 193 189 / 70%);
    font-family: Raleway;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.225rem;
    letter-spacing: 0.00875rem;
    text-transform: capitalize;
  }
`;

export const ActiveNavigationWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const DropdownTypography = styled(Typography)<{ isActive: boolean }>`
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  text-transform: capitalize !important;
  line-height: 135%;
  font-family: Raleway;
  font-weight: 400;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;s
  transition: color 250ms ease-in-out;
  color: ${({ isActive, theme }) => (isActive ? theme.palette.secondary.light : theme.palette.header.textColor)};
  cursor: pointer;

  &:after {
      display: block;
      content: "";
      border-bottom: solid 1px ${({ theme }) => theme.palette.secondary.light};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }

  &:hover:after {
    transform: scaleX(1);
  }

  ${isSmallDesktop} {
    font-size: 1.125rem;
  } ;
`;

export const NavLinkWithoutTo = styled(Typography)`
  font-size: 1.25rem;
  line-height: 135%;
  letter-spacing: 0.01rem;
  font-family: Raleway;
  font-weight: 400;
  cursor: pointer;

  transition: color 250ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.textColor.highlighted};
  }
`;
