import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { Menu, NavLink } from "@ui/interactive";

import { SelectArrowDownIcon } from "@assets/svg";

import { isActiveNavItem } from "@layouts/helpers";
import { ERoutes } from "@utils/constants";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { ActiveNavigationWrapper, DropdownTypography } from "./components";
import { popover } from "./Popover";

type DesktopDropdownProps = {
  title: string;
  navItems: NavItem[];
  activePopperHandler: React.MutableRefObject<() => void | null>;
};

type NavItem = { route: ERoutes; key: string };

export const DesktopDropdown: FC<DesktopDropdownProps> = ({ title, navItems, activePopperHandler }) => {
  const { t } = useTranslation("navigation");

  const location = useLocation();

  const alignerRef = useRef<HTMLParagraphElement>(null);
  const navElems = useRef<HTMLElement[]>([]);

  const [offset, setOffset] = useState<[number, number]>([0, 0]);
  const [flag, setFlag] = useState(false);

  const popupState = usePopupState({ variant: "popper", popupId: "dropdown-menu-popover" });

  const isActive = isActiveNavItem(
    location,
    navItems.map(({ route }) => route),
  );

  if (popupState.isOpen) {
    activePopperHandler.current = () => (popupState.setOpen(false) as any)();
  }

  const handleClose = () => {
    (popupState.close() as any)();
  };

  const renderNavItems = ({ route, key }: NavItem) => (
    <NavLink to={route} end close={handleClose} navElems={navElems}>
      {t(key)}
    </NavLink>
  );

  useEffect(() => {
    if (!popupState.isOpen) {
      navElems.current = [];
      return;
    }

    const firstElemCords = navElems.current[0]?.getBoundingClientRect();

    if (!alignerRef.current || !navElems.current[0] || (firstElemCords && firstElemCords.x === 0)) {
      console.log("Waiting for elements...");
      setFlag((prev) => !prev);
      return;
    }

    console.log("First element coordinates:", firstElemCords);

    const aligner = alignerRef.current.getBoundingClientRect();

    let min = firstElemCords.x;

    navElems.current.forEach((el) => {
      const { x } = el.getBoundingClientRect();
      if (x < aligner.x && x < min) {
        min = x;
      }
    });

    setOffset([aligner.x - min, 15]);
  }, [popupState.isOpen, flag]);

  return (
    <ActiveNavigationWrapper {...bindTrigger(popupState)}>
      <DropdownTypography isActive={isActive} ref={alignerRef}>
        {title}
      </DropdownTypography>{" "}
      <SelectArrowDownIcon />
      <Menu
        popupState={popupState}
        paperProps={{
          ...popover,
          sx: {
            ...popover.sx,
            width: "fit-content",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
        offset={offset}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "14px 24px" }}>
          {navItems.map(renderNavItems)}
        </Box>
      </Menu>
    </ActiveNavigationWrapper>
  );
};
