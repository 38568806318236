import { Stack } from "@mui/material";

import styled, { css } from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

type TWrapper = {
  $withoutBorder?: boolean;
  $withoutBorderOnly?: boolean;
  $isWhiteBorder?: boolean;
  $withPadding?: boolean;
};

export const OuterBorderStack = styled(Stack)<TWrapper>`
  ${({ $withoutBorder, $isWhiteBorder, $withPadding, $withoutBorderOnly }) =>
    !$withoutBorder &&
    css`
      width: 100%;
      height: 100%;

      display: inline-block;
      position: relative;

      ${$withoutBorderOnly &&
      css`
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          padding: 2px;
          border-radius: 16px;

          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      `}

      ${!$isWhiteBorder
        ? css`
            &::before {
              content: "";
              position: absolute;
              inset: 0;
              padding: 2px;
              border-radius: 16px;
              background: ${({ theme }) => theme.palette.gradientButton.mainGradient};
              mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
            }
          `
        : css`
            border: 2px solid #e0d2c1;
            border-radius: 8px;
          `}
      ${isSmallDesktop} {
        padding: 0;
      }

      ${$withPadding &&
      css`
        padding: 58px 56px;
      `}
    `}
`;

export const InnerBorderStack = styled(Stack)<{ $isProfilePage?: boolean } & TWrapper>`
  ${({ $withoutBorder, $isProfilePage }) =>
    !$withoutBorder &&
    css`
      width: 100%;
      height: 100%;

      padding: ${$isProfilePage ? "24px 16px 16px" : "40px 16px"};
      border-radius: 16px;
      background: linear-gradient(180deg, rgba(58, 44, 36, 0.75) 0%, rgba(77, 55, 39, 0.4) 100%);

      ${isSmallDesktop} {
        padding: ${$isProfilePage ? "24px 24px 40px" : "64px"};
      }
    `}
`;
